import { NavigationButtonData } from './types';

const NavigationButtons: NavigationButtonData[] = [
  {
    labelKey: 'MainHomeScreen.navigation.button.home',
    path: '/home',
  },
  {
    labelKey: 'MainHomeScreen.navigation.button.about',
    path: '/about',
  },
  {
    labelKey: 'MainHomeScreen.navigation.button.experience',
    path: '/experience',
  },
  {
    labelKey: 'MainHomeScreen.navigation.button.projects',
    path: '/projects',
  },
  {
    labelKey: 'MainHomeScreen.navigation.button.skills',
    path: '/skills',
  },
  {
    labelKey: 'MainHomeScreen.navigation.button.resume',
    path: '/resume',
  },
  {
    labelKey: 'MainHomeScreen.navigation.button.blogs',
    path: '/blogs',
  },
  {
    labelKey: 'MainHomeScreen.navigation.button.contact',
    path: '/contact',
  },
];

export { NavigationButtons };
