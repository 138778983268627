import { Box, Text } from '@chakra-ui/react';

const Blogs = () => {
  return (
    <Box>
      <Text>Blogs</Text>
    </Box>
  );
};

export default Blogs;
