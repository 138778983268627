import { Box, Text } from '@chakra-ui/react';

const About = () => {
  return (
    <Box>
      <Text>About</Text>
    </Box>
  );
};

export default About;
