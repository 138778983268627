import { Box, Text } from '@chakra-ui/react';

const Resume = () => {
  return (
    <Box>
      <Text>Resume</Text>
    </Box>
  );
};

export default Resume;
