import { Box, Text } from '@chakra-ui/react';

const Contacts = () => {
  return (
    <Box>
      <Text>Contacts</Text>
    </Box>
  );
};

export default Contacts;
