import { Box, Text } from '@chakra-ui/react';

const Experience = () => {
  return (
    <Box>
      <Text>Experience</Text>
    </Box>
  );
};

export default Experience;
