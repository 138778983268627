export const SOCIAL_MEDIA = [
  {
    name: 'LinkedIn',
    url: 'https://in.linkedin.com/in/amitrai1603',
  },
  {
    name: 'GitHub1',
    url: 'https://github.com/onemanfighter',
  },
  {
    name: 'GitHub2',
    url: 'https://github.com/raikwaramit',
  },
  {
    name: 'Medium',
    url: 'https://amitraikwar.medium.com/',
  },
];
