import { Box, Text } from '@chakra-ui/react';

const Skills = () => {
  return (
    <Box>
      <Text>Skills</Text>
    </Box>
  );
};

export default Skills;
